<template>
  <NewPaymentsEditAdmin
          :pm="pm"
          :serverError="serverError"
          @updatePayment="updatePayment"
          @pay="pay"
  />
</template>

<script>
  import NewPaymentsEditAdmin from "./NewPaymentsEditAdmin/NewPaymentsEditAdmin";
  import {NewPayments} from "../../NewPayments";
  import {newPaymentsMixin} from "../../../../../mixins/newPaymentsMixins/newPaymentsMixin";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";

  export default {
    name: "NewPaymentsEdit",

    components: {
      NewPaymentsEditAdmin,
    },

    mixins: [newPaymentsMixin],

    data() {
      return {
        pm: new NewPayments(),
        serverError: false,
      }
    },

    mounted() {
      this.$store.dispatch('getNewPayments', this.$route.params.id).then(() => {
        this.pm.setNewPaymentItem(this.$store.getters.getNewPaymentsItem)
      }).catch(error => this.createErrorLog(error))
    },

    methods: {

      pay() {
        this.newPaymentAccess(this.pm.newPayments.id).then(response => {
          if(response){
            this.payPayment(this.pm.newPayments.id, {goToTable: true})
          }
        })
      },

      updatePayment() {
        if(!this.pm.newPaymentsValidate()) return

        let data = this.pm.newPaymentsPrepare()

        let updateRequestType = 'updateNewPayments'
        if (this.pm.newPayments.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS) {
          updateRequestType = 'updateCommentNewPayments'
        }

        this.$store.dispatch(updateRequestType, {data: data, id: this.pm.newPayments.id}).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$router.push(this.$store.getters.GET_PATHS.financeNewPayment)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    },

  }
</script>

<style scoped>

</style>
